<template>
  <div>
    <div class="inline">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Cập nhật phiếu CSKH'">
          <template v-slot:preview>
            <v-form ref="form" v-model="valid" lazy-validation>
              <b-form-group
                id="input-group-1"
                class="col-3"
                style="padding: 0px"
              >
                <template>
                  <label>Doanh nghiệp:</label>
                </template>
                <b-form-select
                  class="mt-2"
                  size="sm"
                  id="input-4"
                  v-model="selectedCompany"
                  :options="listCompany"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >-- Chọn doanh nghiệp --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                class="col-3"
                style="padding: 0px"
              >
                <template>
                  <label>Kiểu tặng:</label>
                </template>
                <b-form-select
                  class="mt-2"
                  size="sm"
                  id="input-4"
                  v-model="selectedCareType"
                  :options="listType"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >-- Chọn kiểu tặng --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                class="col-3"
                style="padding: 0px"
              >
                <template>
                  <label>Lý do:</label>
                </template>
                <b-form-select
                  class="mt-2"
                  size="sm"
                  id="input-4"
                  v-model="selectedCareForm"
                  :options="listCareForm"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >-- Chọn lý do --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-4"
                class="col-3"
                style="padding: 0px"
              >
                <template>
                  <label>Trị giá:</label>
                  <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="input-4"
                  size="sm"
                  v-model="$v.form.value.$model"
                  :state="validateState('value')"
                  :number = "true"
                  @keypress="onlyNumber($event)"
                  required
                  placeholder="Nhập trị giá"
                  class="mt-2"
                ></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback"
                  >Vui lòng nhập trị giá</b-form-invalid-feedback
                >
              </b-form-group>
              <b-form-group
                id="input-group-5"
                class="col-3"
                style="padding: 0px"
              >
                <label for="input-name">Ghi chú</label>
                <b-form-textarea
                  size="sm"
                  id="textarea1"
                  class="input-style"
                  v-model="note"
                  :placeholder="'Thêm ghi chú'"
                  :rows="6"
                  :max-rows="6"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                class="col-3"
                style="padding: 0px"
              >
                <label for="input-name"
                  >Khách hàng: <span class="text-danger">*</span></label
                >
                <vue-autosuggest
                  aria-describedby="input-phone-live-feedback"
                  :suggestions="filteredOptionsCustomer"
                  @selected="onSelectedCustomer"
                  :limit="10"
                  @input="onInputChangCustomer"
                  :input-props="inputSearchCustomerProp"
                  :should-render-suggestions="
                    (size, loading) => size >= 0 && !loading
                  "
                  v-model="searchCustomerInfo"
                >
                  <div
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    {{ suggestion.item.suggestionName }}
                  </div>
                </vue-autosuggest>
              </b-form-group>
            </v-form>
          </template>
          <template v-slot:foot>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="updateCustomerCare"
              v-if="checkPermission('CUSTOMER_CARE_UPDATE')"
              >Lưu</b-button
            >
            <router-link :to="'/customer-care'">
              <b-button
                :style="
                  checkPermission('CUSTOMER_CARE_UPDATE')
                    ? 'margin-left: 10px; font-weight: 600; width: 70px'
                    : 'margin-left:0px; font-weight: 600; width: 70px'
                "
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
.center-label label {
  margin-bottom: 0px;
}

.form-group label {
  font-weight: 500;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
  z-index: 10000001;
  font-weight: 500;
  background: white;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { TIME_TRIGGER } from './../../../utils/constants';
import decounce from 'debounce';
import localData from '../../../utils/saveDataToLocal';

export default {
  mixins: [validationMixin],
  data() {
    return {
      valid: true,
      listCity: [],
      listDistrict: [],
      isNew: true,
      form: {
        value: '',
      },
      inputSearchCustomerProp: {
        id: 'autosuggest__input',
        placeholder: 'SĐT Khách hàng',
        style: 'border-radius:0px!important',
        disabled: false,
      },
      listCustomer: [],
      filteredOptionsCustomer: [],
      optionsCustomer: [
        {
          data: [],
        },
      ],
      searchCustomer: '',
      searchCustomerInfo: '',
      selectedCareType: null,
      listType: [
        {
          id: 1,
          name: 'Tặng điểm',
        },
        {
          id: 2,
          name: 'Trừ điểm',
        },
      ],
      listCompany: [],
      selectedCompany: null,
      listCareForm: [],
      selectedCareForm: null,
      note: '',
      selectedCustomer: null,
      customerCareId: null,
    };
  },
  validations: {
    form: {
      value: { required },
    },
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Chăm sóc khách hàng', route: '/customer-care' },
      { title: 'Danh sách phiếu CSKH', route: '/customer-care' },
      { title: 'Cập nhật phiếu CSKH' },
    ]);
    this.customerCareId = this.$route.query.id;
    this.getCustomerCareById();
  },
  created() {
    this.fetchCompany();
    this.getListCareForm();
  },
  methods: {
    onlyNumber(evt){
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      const keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) {
           evt.preventDefault()
      } else { 
        return true;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    updateCustomerCare() {
      this.isNew = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let data = {
        id: this.customerCareId,
        point: this.$v.form.value.$model,
        customerCareForm: this.selectedCareForm,
        companyId: this.selectedCompany,
        careType: this.selectedCareType,
        customerId: this.selectedCustomer,
        description: this.note,
      };
      ApiService.setHeader();
      ApiService.put('customerCare', data).then((response) => {
        if (response.data.status === 1) {
          this.makeToastSuccess(response.data.message);
          this.$router.push({
            path: '/customer-care',
          });
        } else {
          this.makeToastFaile(response.data.message);
        }
      });
    },
    fetchCompany: async function () {
      this.listCompany = [];
      ApiService.setHeader();
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
      });
    },
    getListCareForm() {
      this.listCareForm = [];
      ApiService.setHeader();
      ApiService.get(`customerCareForm/getAll`)
        .then((data) => {
          data.data.data.forEach((element) => {
            let item = {
              id: element.id,
              name: element.formName,
            };
            this.listCareForm.push(item);
          });
        })
        .catch(() => {});
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onSelectedCustomer(option) {
      this.searchCustomer = option.item.phoneNo;
      this.selectedCustomer = option.item.id;
      this.searchCustomerInfo = option.item.phoneNo + ` (${option.item.name} )`;
    },
    onInputChangCustomer(text) {
      if (!text) {
        text = '';
      }
      this.searchCustomer = text;
      this.optionsCustomer[0].data = [];
      this.filteredOptionsCustomer = [
        {
          data: this.optionsCustomer[0].data,
        },
      ];
      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      this.debounceInput();
    },
    debounceInput: decounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    getListCustomer() {
      let text = this.searchCustomer;
      ApiService.setHeader();
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          const customers = data.data.data;
          customers.map((element) => {
            let cus = {
              id: element.id,
              phoneNo: element.phoneNo,
              name: element.fullName,
              suggestionName: element.phoneNo + ` (${element.fullName} )`,
            };
            this.optionsCustomer[0].data.push(cus);
          });
          this.filteredOptionsCustomer = [
            {
              data: this.optionsCustomer[0].data,
            },
          ];
        }
      );
    },
    getCustomerCareById: function () {
      ApiService.setHeader();
      ApiService.get(`customerCare/${this.customerCareId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.form.value = data.data.point;
            this.selectedCompany = data.data.companyId;
            this.selectedCareType = data.data.customerCareType;
            this.selectedCareForm = data.data.customerCareForm;
            this.note = data.data.description;
            this.searchCustomer = data.data.customerPhone;
            this.searchCustomerInfo = this.searchCustomerInfo =
              data.data.customerPhone + ` (${data.data.customerName} )`;
            this.selectedCustomer = data.data.customerId;
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    checkPermission(condition) {
      return localData.checkPermission(condition);
    },
  },
};
</script>
